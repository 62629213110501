<template>
	<div class="container">
		<top-header></top-header>
		<div class="prerview">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item to="/datalake" v-if="type == 'literature' || type == 'case'">全球产品大数据湖
					</el-breadcrumb-item>
					<el-breadcrumb-item to="/testing" v-else>产品运行在线监测</el-breadcrumb-item>
					<el-breadcrumb-item to="/literature" v-if="type == 'literature'">文献专利</el-breadcrumb-item>
					<el-breadcrumb-item to="/case" v-if="type == 'case'">经典案例</el-breadcrumb-item>
					<el-breadcrumb-item to="/operation" v-if="type == 'operation'">产品运行情况</el-breadcrumb-item>
					<el-breadcrumb-item :to="{name:'operationinfo',query:{id:mine_id}}" v-if="type == 'operation'">全部信息
					</el-breadcrumb-item>
					<el-breadcrumb-item>文件预览</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="yulan">
					<div class="pdf-perview" v-if="file_info">
						<div class="mask"></div>
						<iframe
							:src="'https://view.officeapps.live.com/op/view.aspx?src=https://api.naipu.cloud/mine/24/2023-12-11/SZKr2CDZ8qxxEBW8RDe7sWbitOgdrlisseesXUhr.docx' + file_info.url"
							:style="{'margin-top':topSize2}" v-if="file_info.product_type == 'doc' || file_info.product_type == 'docx'"></iframe>
						<iframe :src="file_info.url"
							:style="{'margin-top':topSize}" frameborder="0" ref="iframe" v-if="file_info.product_type == 'pdf'"></iframe>
					</div>
					<div class="img-perview" v-if="file_info && file_info.product_type != 'pdf' && file_info.product_type != 'doc' && file_info.product_type != 'docx'">
						<img :src="file_info.url">
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {
				topSize: '-55px',
				topSize2:'-90px',
				type: '',
				mine_id: '',
				file_info:''
			}
		},
		created() {
			if (this.$route.query.type) {
				this.type = this.$route.query.type;
				//console.log(this.type == 'operation');
				if (this.type == 'operation') {
					this.getmineFile();
				}
				if(this.type == 'literature'){
					this.getliterFile();
				}
				if(this.type == 'case'){
					this.getcaseFile();
				}
			}
			if (this.$route.query.mine_id) {
				this.mine_id = this.$route.query.mine_id;
			}
			// if(this.$route.query.type == 'operation'){
			// 	this.getoperationInfo();
			// }
		},
		mounted() {
			document.onkeydown = function() {
				var e = window.event || arguments[0];
				if (e.keyCode == 123) {
					// alert('F12无效');
					return false;
				} else if ((e.ctrlKey) && (e.shiftKey) && (e.keyCode == 73)) {
					// alert('F12无效');
					return false;
				} else if ((e.ctrlKey) && (e.keyCode == 85)) {
					// alert('F12无效');
					return false;
				} else if ((e.ctrlKey) && (e.keyCode == 83)) {
					// alert('F12无效'); 
					return false;
				}
			}
			document.oncontextmenu = function() {
				return false
			};
			// document.ondragstart = function() {
			// 	return false
			// };
			// document.onselectstart = function() {
			// 	return false
			// };
			// document.onselect = function() {
			// 	document.selection.empty();
			// }; //禁止选取
			// document.oncopy = function() {
			// 	document.selection.empty();
			// }; //禁止复制
			// document.onbeforecopy = function() {
			// 	return false
			// };
			// document.onmouseup = function() {
			// 	document.selection.empty();
			// };

		},
		methods: {
			getcaseFile(){
				this.$get('front/product/get_details', {
					id: this.$route.query.id
				}).then(res => {
					
					let fileName = res.data.data.url.lastIndexOf(".");//取到文件名开始到最后一个点的长度
					let fileNameLength = res.data.data.url.length;//取到文件名长度
					let fileFormat = res.data.data.url.substring(fileName + 1, fileNameLength);//截
					res.data.data.product_type = fileFormat;
					res.data.data.url = this.$store.state.imaUrl + res.data.data.url;
					this.file_info = res.data.data;
					//console.log(res);
				})
			},
			getliterFile(){
				this.$get('front/literature/get_details', {
					id: this.$route.query.id
				}).then(res => {
					
					let fileName = res.data.data.url.lastIndexOf(".");//取到文件名开始到最后一个点的长度
					let fileNameLength = res.data.data.url.length;//取到文件名长度
					let fileFormat = res.data.data.url.substring(fileName + 1, fileNameLength);//截
					res.data.data.product_type = fileFormat;
					res.data.data.url = this.$store.state.imaUrl + res.data.data.url;
					this.file_info = res.data.data;
					//console.log(res);
				})
			},
			getmineFile() {
				this.$get('front/mine_file/get_details', {
					id: this.$route.query.id
				}).then(res => {
					let fileName = res.data.data.url.lastIndexOf(".");//取到文件名开始到最后一个点的长度
					let fileNameLength = res.data.data.url.length;//取到文件名长度
					let fileFormat = res.data.data.url.substring(fileName + 1, fileNameLength);//截
					res.data.data.product_type = fileFormat;
					res.data.data.url = this.$store.state.imaUrl + res.data.data.url;
					this.file_info = res.data.data;
					// console.log(this.file_info);
				})
			},
			// getoperationInfo(){
			// 	this.$get('front/mine/get_details',{
			// 		id:this.$route.query.id
			// 	}).then(res =>{
			// 		console.log(res);
			// 	})
			// },
		}
	}
</script>

<style lang="scss">
	.prerview {
		margin-top: 60px;
		min-height: 87.8vh;
		padding-top: 20px;
		box-sizing: border-box;

		.yulan {
			background-color: #fff;
			padding: 20px;
			box-sizing: border-box;
			margin-top: 20px;
			min-height: 75vh;

			.img-perview {
				img {
					max-width: 100%;
					display: block;
					margin: 20px auto;
				}
			}

			.pdf-perview {
				position: relative;
				overflow: hidden;

				// .mask {
				// 	position: absolute;
				// 	width: 800px;
				// 	height: 730px;
				// 	z-index: 1;
				// 	left: 50%;
				// 	margin-left: -420px;
				// 	filter: alpha(opacity=0);
				// 	opacity: 0;
				// 	background: red;
				// }

				iframe {
					width: 800px;
					height: 680px;
					display: block;
					margin: 0 auto;
					// pointer-events: none;
				}
			}
		}
	}
</style>
